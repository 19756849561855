import React, { Component } from 'react';
import ModalWrapper from './ModalWrapper';
import ModalHeader from './ModalHeader';
import BoxMessage from '../BoxMessage';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import localeLookup from '../../config/locale';
import {
  ACCESS_LEVELS,
  ACTION_STATES,
  TRAINING_REGISTRATION_TYPES,
} from '../../constants';
import Icon from '../Icon';
import Text from '../Text';
import Button from '../Button';
import DropdownButton from '../DropdownButton';
import Accordion from '../Accordion';
import DateFieldV2 from '../DateFieldV2';
import Textarea from '../Textarea';
import SignaturePad from '../SignaturePad';
import { connect } from 'react-redux';
import ButtonIcon from '../ButtonIcon';
import ListCard from '../ListCard';
import cx from 'classnames';
import withAccessControl from '../HOC/withAccessControl';
import withPersonLookup from '../HOC/withPersonLookup';
import { compose } from 'redux';
import { registerBulkTrainingService } from '../../services/trainingService';
import { format } from 'date-fns';
import { compareLocal, sortBy } from '../../utils/helpers';

const mapStateToProps = (state) => {
  const { user } = state;
  return { currentUserName: user.name };
};

class BulkCompletionModal extends Component {
  constructor() {
    super();
    this.state = {
      elementsThatCanBeCompleted: {
        base64signature: '',
        elements: [],
        elementsGroupedByPerson: [],
        expandedPersonIds: [],
        action: '',
        showElements: false,
        isCompleted: false,
        isLoading: false,
        adminCompleteDate: new Date(),
        adminCompleteNote: '',
      },
      elementsThatCanBeCompletedWithSignature: {
        base64signature: '',
        elements: [],
        elementsGroupedByPerson: [],
        expandedPersonIds: [],
        action: '',
        showElements: false,
        isCompleted: false,
        isLoading: false,
        adminCompleteDate: new Date(),
        adminCompleteNote: '',
      },
      elementsThatCannotBeCompleted: {
        base64signature: '',
        elements: [],
        elementsGroupedByPerson: [],
        expandedPersonIds: [],
        action: '',
        showElements: false,
        isCompleted: false,
        isLoading: false,
        adminCompleteDate: new Date(),
        adminCompleteNote: '',
      },
    };
  }

  componentDidMount() {
    this.groupElements();
  }

  groupElements = () => {
    const { elements } = this.props;
    const elementsThatCanBeCompleted = [];
    const elementsThatCanBeCompletedWithSignature = [];
    const elementsThatCannotBeCompleted = [];
    elements.forEach((element) => {
      switch (element.nextAction) {
        case ACTION_STATES.COMPLETE:
          elementsThatCanBeCompleted.push(element);
          break;
        case ACTION_STATES.COMPLETE_WITH_SIGNATURE:
          elementsThatCanBeCompletedWithSignature.push(element);
          break;
        case ACTION_STATES.LOCKED:
        case ACTION_STATES.PENDING_COUNTERPART:
        case ACTION_STATES.REQUEST_SIGNATURE:
          elementsThatCannotBeCompleted.push(element);
          break;
        default:
          break;
      }
    });
    this.setState({
      elementsThatCanBeCompleted: {
        ...this.state.elementsThatCanBeCompleted,
        elements: elementsThatCanBeCompleted,
        elementsGroupedByPerson: elementsThatCanBeCompleted.reduce(
          (acc, element) => {
            if (acc[element.personId]) {
              return {
                ...acc,
                [element.personId]: [
                  ...acc[element.personId],
                  element.elementId,
                ],
              };
            } else {
              return {
                ...acc,
                [element.personId]: [element.elementId],
              };
            }
          },
          {}
        ),
      },
      elementsThatCanBeCompletedWithSignature: {
        ...this.state.elementsThatCanBeCompletedWithSignature,
        elements: elementsThatCanBeCompletedWithSignature,
        elementsGroupedByPerson: elementsThatCanBeCompletedWithSignature.reduce(
          (acc, element) => {
            if (acc[element.personId]) {
              return {
                ...acc,
                [element.personId]: [
                  ...acc[element.personId],
                  element.elementId,
                ],
              };
            } else {
              return {
                ...acc,
                [element.personId]: [element.elementId],
              };
            }
          },
          {}
        ),
      },
      elementsThatCannotBeCompleted: {
        ...this.state.elementsThatCannotBeCompleted,
        elements: elementsThatCannotBeCompleted,
        elementsGroupedByPerson: elementsThatCannotBeCompleted.reduce(
          (acc, element) => {
            if (acc[element.personId]) {
              return {
                ...acc,
                [element.personId]: [
                  ...acc[element.personId],
                  element.elementId,
                ],
              };
            } else {
              return {
                ...acc,
                [element.personId]: [element.elementId],
              };
            }
          },
          {}
        ),
      },
    });
  };

  onChangeAction = (state, action) => {
    this.setState({
      [state]: {
        ...this.state[state],
        showElements: false,
        action,
      },
    });
  };

  onChangeAdminCompleteDate = (state, date) => {
    this.setState({
      [state]: {
        ...this.state[state],
        adminCompleteDate: date,
      },
    });
  };

  onChangeAdminCompleteNote = (state, e) => {
    this.setState({
      [state]: {
        ...this.state[state],
        adminCompleteNote: e.target.value,
      },
    });
  };

  onClickCompleteElements = (state) => {
    const { onElementsCompleted } = this.props;
    this.setState(
      {
        [state]: {
          ...this.state[state],
          isLoading: true,
        },
      },
      () => {
        const { elementsGroupedByPerson } = this.state[state];
        const personIds = Object.keys(elementsGroupedByPerson);
        const data = personIds.reduce((acc, personId) => {
          return [
            ...acc,
            { menteeId: personId, elements: elementsGroupedByPerson[personId] },
          ];
        }, []);
        registerBulkTrainingService({
          type: TRAINING_REGISTRATION_TYPES.STANDARD,
          operations: data,
        }).then(() => {
          onElementsCompleted(personIds);
          this.setState({
            [state]: {
              ...this.state[state],
              isLoading: false,
              isCompleted: true,
              action: '',
            },
          });
        });
      }
    );
  };

  onClickAdminCompleteElements = (state) => {
    const { onElementsCompleted } = this.props;
    const { adminCompleteDate, adminCompleteNote } = this.state[state];
    this.setState(
      {
        [state]: {
          ...this.state[state],
          isLoading: true,
        },
      },
      () => {
        const { elementsGroupedByPerson } = this.state[state];
        const personIds = Object.keys(elementsGroupedByPerson);
        const data = personIds.reduce((acc, personId) => {
          return [
            ...acc,
            { menteeId: personId, elements: elementsGroupedByPerson[personId] },
          ];
        }, []);
        registerBulkTrainingService({
          type: TRAINING_REGISTRATION_TYPES.ADMIN_APPROVAL,
          note: adminCompleteNote,
          validFromDate: format(adminCompleteDate, 'yyyy-MM-dd'),
          operations: data,
        }).then(() => {
          onElementsCompleted(personIds);
          this.setState({
            [state]: {
              ...this.state[state],
              isLoading: false,
              isCompleted: true,
              action: '',
            },
          });
        });
      }
    );
  };

  onClickSignElements = (state) => {
    const { onElementsCompleted } = this.props;
    const { base64signature } = this.state[state];
    this.setState(
      {
        [state]: {
          ...this.state[state],
          isLoading: true,
        },
      },
      () => {
        const { elementsGroupedByPerson } = this.state[state];
        const personIds = Object.keys(elementsGroupedByPerson);
        const data = personIds.reduce((acc, personId) => {
          return [
            ...acc,
            { menteeId: personId, elements: elementsGroupedByPerson[personId] },
          ];
        }, []);
        registerBulkTrainingService({
          type: TRAINING_REGISTRATION_TYPES.STANDARD,
          signature: { signature: base64signature, metadata: {} },
          operations: data,
        }).then(() => {
          onElementsCompleted(personIds);
          this.setState({
            [state]: {
              ...this.state[state],
              isLoading: false,
              isCompleted: true,
              action: '',
            },
          });
        });
      }
    );
  };

  onClickToggleExpandPerson = (state, personId) => {
    this.setState((prevState) => ({
      [state]: {
        ...this.state[state],
        expandedPersonIds: prevState[state].expandedPersonIds.includes(personId)
          ? prevState[state].expandedPersonIds.filter(
              (expandedId) => expandedId !== personId
            )
          : [...prevState[state].expandedPersonIds, personId],
      },
    }));
  };

  onClickToggleShowElements = (state) => {
    this.setState((prevState) => ({
      [state]: {
        ...this.state[state],
        showElements: !prevState[state].showElements,
        action: !prevState[state].showElements ? '' : prevState[state].action,
      },
    }));
  };

  onEndSignature = (state, base64signature) => {
    this.setState({
      [state]: {
        ...this.state[state],
        base64signature,
      },
    });
  };

  renderActionDropdown = (state) => {
    const { hasAccess } = this.props;
    const {
      elementsThatCanBeCompleted,
      elementsThatCanBeCompletedWithSignature,
      elementsThatCannotBeCompleted,
    } = this.state;
    const { isLoading, isCompleted, action } = this.state[state];
    const isAdministrator = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
    ]);
    const getActions = () => {
      switch (state) {
        case 'elementsThatCanBeCompleted':
          return [
            {
              label: elementsThatCanBeCompleted.isCompleted
                ? localeLookup('translations.Completed')
                : localeLookup('translations.Complete'),
              id: 'complete',
            },
            ...(isAdministrator
              ? [
                  {
                    label: elementsThatCanBeCompleted.isCompleted
                      ? localeLookup('translations.Completed as admin')
                      : localeLookup('translations.Complete as admin'),
                    id: 'adminComplete',
                  },
                ]
              : []),
          ];
        case 'elementsThatCanBeCompletedWithSignature':
          return [
            {
              label: elementsThatCanBeCompletedWithSignature.isCompleted
                ? localeLookup('translations.Signed')
                : localeLookup('translations.Sign'),
              id: 'sign',
            },
            ...(isAdministrator
              ? [
                  {
                    label: elementsThatCanBeCompletedWithSignature.isCompleted
                      ? localeLookup('translations.Completed as admin')
                      : localeLookup('translations.Complete as admin'),
                    id: 'adminComplete',
                  },
                ]
              : []),
          ];
        case 'elementsThatCannotBeCompleted':
          if (isAdministrator) {
            return [
              {
                label: elementsThatCannotBeCompleted.isCompleted
                  ? localeLookup('translations.Completed as admin')
                  : localeLookup('translations.Complete as admin'),
                id: 'adminComplete',
              },
            ];
          }
          return [];
        default:
          break;
      }
    };
    const actions = getActions();
    if (actions.length === 0) return null;
    return (
      <DropdownButton
        isLoading={isLoading}
        disabled={isLoading || isCompleted}
        color={isCompleted ? 'green' : ''}
        icon={isCompleted ? 'check' : null}
        onChange={(action) => this.onChangeAction(state, action)}
        selectedItem={action}
        placeholder={
          isCompleted
            ? localeLookup('translations.Completed')
            : localeLookup('translations.Action')
        }
        items={actions}
      />
    );
  };

  renderAdminCompletionAccordionContent = (state) => {
    const { adminCompleteDate, adminCompleteNote, isLoading } =
      this.state[state];

    return (
      <>
        <DateFieldV2
          label={localeLookup('translations.Valid from')}
          value={adminCompleteDate}
          maxDate={new Date()}
          onChange={(date) => this.onChangeAdminCompleteDate(state, date)}
        />
        <Textarea
          maxLength={255}
          placeholder={localeLookup('translations.Add a note')}
          onChange={(e) => this.onChangeAdminCompleteNote(state, e)}
          value={adminCompleteNote}
        />
        <Button
          fullWidth
          className="bulk-completion-modal__section-button"
          kind="darkui"
          disabled={isLoading}
          onClick={() => this.onClickAdminCompleteElements(state)}
        >
          {localeLookup('translations.Complete')}
        </Button>
      </>
    );
  };

  renderCompletionAccordionContent = (state) => {
    const { isLoading } = this.state[state];
    return (
      <>
        <Text>
          {localeLookup(
            'translations.This change cannot be undone as a bulk action, but can be changed on the individual elements afterwards'
          )}
        </Text>
        <Button
          fullWidth
          className="bulk-completion-modal__section-button"
          kind="darkui"
          disabled={isLoading}
          onClick={() => this.onClickCompleteElements(state)}
        >
          {localeLookup('translations.Complete')}
        </Button>
      </>
    );
  };

  renderElements = (state) => {
    const { lookupPerson, elements } = this.props;
    const { elementsGroupedByPerson, expandedPersonIds } = this.state[state];
    const sortedPersonIds = sortBy(Object.keys(elementsGroupedByPerson), [
      (a, b) => {
        const personA = lookupPerson(a);
        const personB = lookupPerson(b);
        return compareLocal(personA.name, personB.name);
      },
    ]);
    return sortedPersonIds.map((personId) => {
      const person = lookupPerson(personId);
      const sortedElementIds = sortBy(elementsGroupedByPerson[personId], [
        (a, b) => {
          const elementA = elements.find((element) => element.elementId === a);
          const elementB = elements.find((element) => element.elementId === b);
          return compareLocal(elementA.elementName, elementB.elementName);
        },
      ]);
      if (!person) return null;
      return (
        <div
          key={personId}
          className={cx('bulk-completion-modal__element-list-person', {
            'bulk-completion-modal__element-list-person--state-expanded':
              expandedPersonIds.includes(personId),
          })}
        >
          <ListCard
            tooltip={`${person.initials} ${
              person.employeeNumber ? `· ${person.employeeNumber}` : ''
            }`}
            clickable
            noCardStyling
            key={personId}
            onClick={() => this.onClickToggleExpandPerson(state, personId)}
            rightComponent={
              <ButtonIcon
                className="bulk-completion-modal__element-list-person-expand-icon"
                inline
                icon="chevron-down"
              />
            }
          >
            <>
              <Text>{person.name}</Text>
            </>
          </ListCard>
          <Accordion isOpen={expandedPersonIds.includes(personId)}>
            {expandedPersonIds.includes(personId)
              ? sortedElementIds.map((elementId) => {
                  const element = elements.find(
                    (element) => element.elementId === elementId
                  );
                  return (
                    <ListCard clickable noCardStyling key={element.elementId}>
                      <>
                        <Text>{element.elementName}</Text>
                        {state === 'elementsThatCannotBeCompleted' ? (
                          <Text size="sm" color="red">
                            {element.nextAction === ACTION_STATES.LOCKED
                              ? localeLookup('translations.Locked')
                              : localeLookup(
                                  'translations.Requires signature from other person'
                                )}
                          </Text>
                        ) : null}
                      </>
                    </ListCard>
                  );
                })
              : null}
          </Accordion>
        </div>
      );
    });
  };

  renderElementsThatCanBeCompletedSection = () => {
    return (
      <section className="bulk-completion-modal__section">
        {this.renderSectionHeader(
          'elementsThatCanBeCompleted',
          'check',
          'green',
          'elements can be completed'
        )}
        {this.renderSectionAccordion('elementsThatCanBeCompleted')}
      </section>
    );
  };
  renderElementsThatCanBeCompletedWithSignatureSection = () => {
    return (
      <section className="bulk-completion-modal__section">
        {this.renderSectionHeader(
          'elementsThatCanBeCompletedWithSignature',
          'sign',
          'green',
          'elements requires signature from you'
        )}
        {this.renderSectionAccordion('elementsThatCanBeCompletedWithSignature')}
      </section>
    );
  };
  renderElementsThatCannotBeCompletedSection = () => {
    return (
      <section className="bulk-completion-modal__section">
        {this.renderSectionHeader(
          'elementsThatCannotBeCompleted',
          'cross-circle',
          'red',
          'elements can not be completed'
        )}
        {this.renderSectionAccordion('elementsThatCannotBeCompleted')}
      </section>
    );
  };

  renderSectionAccordion = (state) => {
    const { action, showElements } = this.state[state];
    return (
      <Accordion isOpen={action || showElements}>
        <main
          className={cx('bulk-completion-modal__section-main', {
            'bulk-completion-modal__section-main--background-grey':
              showElements,
          })}
        >
          {showElements ? this.renderElements(state) : null}
          {action === 'complete'
            ? this.renderCompletionAccordionContent(state)
            : null}
          {action === 'sign'
            ? this.renderSignatureCompletionAccordionContent(state)
            : null}
          {action === 'adminComplete'
            ? this.renderAdminCompletionAccordionContent(state)
            : null}
        </main>
      </Accordion>
    );
  };

  renderSectionHeader = (state, icon, iconColor, title) => {
    const { elements, showElements } = this.state[state];
    return (
      <header className="bulk-completion-modal__section-header">
        <div className="bulk-completion-modal__section-header-left">
          <Icon kind={icon} color={iconColor}></Icon>
        </div>
        <div className="bulk-completion-modal__section-header-center">
          <Text>
            {elements.length} {localeLookup(title, [elements.length])}
          </Text>
          <Button
            kind="link-style"
            onClick={() => this.onClickToggleShowElements(state)}
          >
            {showElements
              ? localeLookup('Hide elements')
              : localeLookup('Show elements')}
          </Button>
        </div>
        <div className="bulk-completion-modal__section-header-right">
          {this.renderActionDropdown(state)}
        </div>
      </header>
    );
  };

  renderSignatureCompletionAccordionContent = (state) => {
    const { base64signature, isLoading } = this.state[state];
    const { currentUserName } = this.props;
    return (
      <>
        <SignaturePad
          signee={currentUserName}
          onEndSignature={(signature) => this.onEndSignature(state, signature)}
        />
        <Button
          fullWidth
          className="bulk-completion-modal__section-button"
          kind="darkui"
          disabled={isLoading || base64signature === ''}
          onClick={() => this.onClickSignElements(state)}
        >
          {localeLookup('translations.Sign')}
        </Button>
      </>
    );
  };

  render() {
    const { infoText, title, subtitle, onClose, infoTextType, infoTextIcon } =
      this.props;
    const {
      elementsThatCanBeCompleted,
      elementsThatCanBeCompletedWithSignature,
      elementsThatCannotBeCompleted,
    } = this.state;
    return (
      <ModalWrapper className="bulk-completion-modal">
        <ModalHeader
          title={localeLookup('translations.Complete')}
          subtitle={subtitle}
          onClose={onClose}
        />
        {infoText && (
          <BoxMessage
            spacing="md"
            type={infoTextType}
            icon={infoTextIcon || 'info-circle'}
          >
            {infoText}
          </BoxMessage>
        )}
        <ModalBody>
          {elementsThatCanBeCompleted.elements.length > 0
            ? this.renderElementsThatCanBeCompletedSection()
            : null}
          {elementsThatCanBeCompletedWithSignature.elements.length > 0
            ? this.renderElementsThatCanBeCompletedWithSignatureSection()
            : null}
          {elementsThatCannotBeCompleted.elements.length > 0
            ? this.renderElementsThatCannotBeCompletedSection()
            : null}
        </ModalBody>
        <ModalFooter
          cancelButtonText={localeLookup('translations.Close')}
          onCancelClick={onClose}
        />
      </ModalWrapper>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withAccessControl,
  withPersonLookup
)(BulkCompletionModal);
