import React, { PureComponent } from 'react';
import Icon from './Icon';
import Loader from './Loader';
import cx from 'classnames';

export default class DropdownButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedItem: props.selectedItem || '',
    };
  }

  onClickItem = (itemId) => {
    const { onChange } = this.props;
    this.setState(
      {
        selectedItem: itemId,
        isOpen: false,
      },
      () => {
        onChange(itemId);
      }
    );
  };

  onClickToggleOpen = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  renderIcon = () => {
    const { isLoading, color, icon } = this.props;

    if (isLoading) {
      return (
        <Loader color={color ? 'white' : ''} size="small" numberOfDots={1} />
      );
    }
    if (icon) {
      return <Icon kind={icon} color={color ? 'white' : 'grey'} size="small" />;
    }
    return (
      <Icon color={color ? 'white' : 'grey'} kind="chevron-down" size="small" />
    );
  };

  render() {
    const { isOpen, selectedItem } = this.state;
    const { placeholder, items, isLoading, color, disabled } = this.props;
    const modifierClasses = {
      [`dropdown-button--color-${color}`]: color,
    };

    return (
      <div className={cx('dropdown-button', modifierClasses)}>
        <button
          onClick={disabled ? null : this.onClickToggleOpen}
          className="dropdown-button__button"
        >
          {isLoading ? null : (
            <span className="dropdown-button__text">
              {selectedItem
                ? items.find((item) => item.id === selectedItem).label
                : placeholder}
            </span>
          )}
          <span className="dropdown-button__icon">{this.renderIcon()}</span>
        </button>
        {isOpen ? (
          <ul className="dropdown-button__list">
            <li className="dropdown-button__item">
              <button
                onClick={() => this.onClickItem('')}
                className="dropdown-button__item-button"
              >
                {placeholder}
              </button>
            </li>
            {items.map((item) => (
              <li key={item.id} className="dropdown-button__item">
                <button
                  onClick={() => this.onClickItem(item.id)}
                  className="dropdown-button__item-button"
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  }
}
