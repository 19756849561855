import React, { useState } from 'react';
import { AnimatePresence, createDomMotionComponent } from 'framer-motion';

const motion = {
  section: createDomMotionComponent('section'),
};

const Accordion = ({ isOpen, children, className }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.section
          className={className}
          key="content"
          initial="collapsed"
          animate="open"
          exit="collapsed"
          onAnimationStart={() => {
            console.log('start');
            setIsAnimating(true);
          }}
          onAnimationComplete={() => {
            setIsAnimating(false);
          }}
          style={{ overflow: isAnimating ? 'hidden' : 'visible' }}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
          }}
          transition={{ duration: .15 }}
        >
          {children}
        </motion.section>
      )}
    </AnimatePresence>
  );
};

export default Accordion;
